import { components } from "react-select";

const CheckboxOption = (props) => {

    return (
        <div>
            <components.Option className="flex" {...props} >
                <div className="flex items-start gap-2">

                    <input
                        className="block mt-1.5"
                        type="checkbox"
                        checked={props.isSelected}
                        onChange={() => null}
                    />{" "}
                    <label>{props.label}</label>
                </div>
            </components.Option>
        </div>
    );
};

export default CheckboxOption;