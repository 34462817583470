import React from 'react'
import { components } from 'react-select';
import IconCloseThick from 'atoms/SvgIcons/IconCloseThick.svg';

const RSMultiValueRemove = (props) => {
    return (
        <components.MultiValueRemove {...props}>
            <div className='pl-1 pr-2 flex h-full items-center group'>
                <IconCloseThick className='w-4 fill-gray-700 group-hover:fill-primary1-500' />
            </div>
        </components.MultiValueRemove>
    )
}

export default RSMultiValueRemove