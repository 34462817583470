import IconSearchThick from 'atoms/SvgIcons/IconSearchThick.svg';
import React, { useState } from 'react'
import { components } from 'react-select';


const RSInput = (props) => {
    const [isFocus, setIsFocus] = useState(false);
    const selectedValue = props?.getValue();

    if (props.isHidden) {
        return <components.Input {...props} />;
    }
    if (props?.theme?.dropdownTheme == 'CHECKBOX') {
        return (
            <div className={`${props.hasValue ? 'min-h-9.5' : 'min-h-10.5'} w-full flex items-center px-3.5`}>
                {props.value == '' && selectedValue?.length == 0
                    ? props?.selectProps?.placeholder
                        ? <div className='text-gray-400 absolute ml-0.5'> {props?.selectProps?.placeholder} </div>
                        : 'Search'
                    : (isFocus && selectedValue?.length != 0 && props.value == '') || (!isFocus && selectedValue?.length != 0)
                        ? <div className='whitespace-nowrap'>{`${selectedValue.length} selected`} </div>
                        : ''}

                <components.Input className='w-full' {...props} onFocus={(e) => { setIsFocus(true); props.onFocus(e); }} onBlur={(e) => { setIsFocus(false); props.onBlur(e); }} />
            </div>
        );
    } else {

        return (
            <div className={`${props.hasValue ? 'min-h-9.5' : 'min-h-10.5'} w-full flex items-center px-3.5`}>
                <IconSearchThick className="flex-shrink-0 w-6 fill-gray-400 mr-2" />
                {(!props.value) && (
                    <div className='relative'>
                        <div className={`${props.isDisabled ? 'text-gray-500' : 'text-gray-400'} absolute left-0.5 transform -translate-y-1/2 font-Inter font-normal text-sm`}>Search</div>
                    </div>
                )}

                <components.Input className='w-full' {...props} />
            </div>
        );
    }
};

export default RSInput