import { Svg } from "atoms/SvgIcon";

const IconInformation = ({ ...rest }) => {
    return <Svg viewBox='0 0 24 24' type='IconInformation' {...rest} >
        <path d="M10.9544 19.5622C10.2784 19.5622 9.66924 19.392 9.27805 18.899C8.38258 17.773 9.32902 16.0155 10.4231 13.9819C10.9322 13.0355 11.88 11.2758 11.7178 10.8329C11.721 10.8416 11.6352 10.7996 11.3696 10.7996C11.0787 10.7996 10.5712 10.8513 9.72674 11.082L9.36997 10.028C10.6853 9.37045 12.2318 8.86059 13.0034 9.83662C13.8988 10.9635 12.9524 12.7202 11.8583 14.7538C11.3492 15.7002 10.4014 17.4599 10.5636 17.902C10.5684 17.9037 10.6479 17.937 10.9118 17.937C11.2027 17.937 11.7102 17.8845 12.5547 17.6539L12.9864 18.9048C12.3287 19.2335 11.6129 19.5622 10.9544 19.5622L10.9544 19.5622Z" fill="currentColor" />
        <path d="M14.4016 5.40977C14.4016 6.30916 13.6724 7.0383 12.7731 7.0383C11.8737 7.0383 11.1445 6.30916 11.1445 5.40977C11.1445 4.51039 11.8737 3.78125 12.7731 3.78125C13.6724 3.78125 14.4016 4.51039 14.4016 5.40977" fill="currentColor" />
        <path d="M12 24C5.38333 24 0 18.6167 0 12C0 5.38333 5.38333 0 12 0C18.6167 0 24 5.38333 24 12C24 18.6167 18.6167 24 12 24ZM12 1.64381C6.28952 1.64381 1.64381 6.28962 1.64381 12C1.64381 17.7104 6.28962 22.3562 12 22.3562C17.7104 22.3562 22.3562 17.7104 22.3562 12C22.3562 6.28962 17.7104 1.64381 12 1.64381V1.64381Z" fill="currentColor" />
    </Svg>
};

export default IconInformation;