import IconCloseThick from 'atoms/SvgIcons/IconCloseThick.svg';
import React from 'react'
import { components } from 'react-select';

const RSClearIndicator = (props) => {
    const selectedValue = props?.getValue();

    return (
        <components.ClearIndicator className='group cursor-pointer' {...props}>
            {props?.theme?.dropdownTheme == 'CHECKBOX' && props.isFocused && selectedValue?.length != 0
                ? <div></div>
                : <div>
                    <IconCloseThick className='w-6 fill-gray-500 group-hover:fill-primary1-500' />
                </div>
            }
        </components.ClearIndicator>
    )
}

export default RSClearIndicator