import React from 'react'
import Select, { components } from 'react-select';


const SelectContainer = ({ children, ...props }) => {
    return (
        <div>
            {props.hasValue && <div className=' text-gray-400 absolute z-1 transition-all rounded-full -top-2.5 left-2 text-xs py-0 px-2 bg-basic-white'>{props?.selectProps?.placeholder}</div>}
            <components.SelectContainer {...props}>
                {children}
            </components.SelectContainer>
        </div>
    );
};

export default SelectContainer